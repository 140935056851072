footer {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    svg {
        width: 1.1em !important;
        height: 1.1em !important;
        margin-right: 10px;
    }

    p {
        font-size: 0.8em;
    }
} 