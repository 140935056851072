.dark-mode .about-section {
    background-color: rgba(0, 0, 0, 0.2);
}

.about-section {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 15%;
    min-height: 700px;
    background-image: url("../images/bg-dark.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.title{
    font-size: 100 px;
    color: #f2cc8f;
    font-weight: bold;
    padding: 0 0 0 10px;
}



.about-section .content {
    text-align: left;
    z-index: 2;

    .social_icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: white;

        svg {
            font-size: 1.8em;
        }
    }

    .mobile_social_icons {
        display: none;
    }

    p {
        font-size: 1.5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    h1 {
        font-size: 5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
}

.about-section .image-wrapper {
    z-index: 2;
    img {
        width: 250px;
        border-radius: 50%;
    }
}

.about p {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
}

@media screen {
    @media (max-width: 567px) {
        .nav-left{
            padding-top: 20px;
            float:left;
        }

        .nav-left img {
            height: 35px
        }

        .nav-right {
            float:right;
        }

        .nav-right li {
            padding: 10px 15px;
            list-style:none;
            font-size:13px;
            color:white;
            width: 10%;
        }

        .nav-right ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

        body {
            width: 100%;
        }

        .about {
            padding-left: 5%;
            padding-right: 5%;
            font-size: 12px;
        }
    }

}